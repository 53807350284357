import React  from 'react';
import {useEffect } from 'react'
import MainLayout from './components/layouts/MainLayout'


function Contact() {

	useEffect(() => {
		document.title = "ติดต่อเรา";  
	}, []);

  return (
    <MainLayout>
        
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3880.4645483021413!2d101.03163371534552!3d13.44543670794028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d370465112e59%3A0xaca69425f428e248!2z4Lia4Lij4Li04Lip4Lix4LiXIOC5guC4i-C4meC4teC5iCDguYDguJfguITguYLguJnguYLguKXguKLguLUgKOC4m-C4o-C4sOC5gOC4l-C4qOC5hOC4l-C4oikgKOC4quC4suC4guC4siAxKQ!5e0!3m2!1sth!2sth!4v1642927369044!5m2!1sth!2sth" width={'100%'} height={500} style={{border: 0}} allowFullScreen loading="lazy" /> */}

        <div className="container mb-5">
          <h1 className="my-4">ติดต่อเรา</h1>
          <p>บริษัท บีพีไอ เอ็นจิเนียริ่ง แอนด์ เซอร์วิส จำกัด</p>
          <p>187/130 หมู่ที่ 5 ตำบลเชิงเนิน อำเภอเมืองระยอง จังหวัดระยอง รหัสไปรษณีย์ 21000 </p>
          <p>โทรศัพท์ (66)81-590-4681, (66)87-583-3031</p>
          <p>อีเมล์ admin@bpi.co.th</p>
        </div>
    </MainLayout>
  )
}

export default Contact

