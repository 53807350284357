import React, { forwardRef } from "react"
const Footer = (props, ref)=>(
    //console.log("props=",props)
    <>
      <div className="place-holder" style={{ height: props.height + "px" }} />  
      <section ref={ref} className={"footer mt-3 "+ props.cls}>
        <div className="container">
          <footer className="py-1">
            <div className="d-flex justify-content-between py-4 border-top">
              © 2022 BPI Engineering and Service Co., Ltd. All rights reserved.
            </div>
          </footer>
        </div>
      </section>
    </>
)

export default forwardRef( Footer);