import React from 'react'

function Tools(props) {
	let file = []
	let i = 0
	if (props.data) {
		props.data.forEach((item) => {
			console.log('item=', item)
			switch (item) {
				case 'php':
					console.log('is ok')
					file.push({ key: i, title: 'PHP', file: 'assets/images/icons/php.png' })
					break
				case 'dotnet':
					file.push({ key: i, title: 'DotNet', file: 'assets/images/icons/dotnet.png' })
					break
				case 'c#':
					file.push({ key: i, title: 'C#', file: 'assets/images/icons/c.png' })
					break
				case 'ci':
					file.push({ key: i, title: 'CodeIgniter', file: 'assets/images/icons/ci.png' })
					break
				case 'jquery':
					file.push({ key: i, title: 'JQuery', file: 'assets/images/icons/jquery.png' })
					break
				case 'bootstrap':
					file.push({ key: i, title: 'BootStrap', file: 'assets/images/icons/bootstrap.png' })
					break
				case 'jquerym':
					file.push({ key: i, title: 'JQuery Mobile', file: 'assets/images/icons/jquery_mobile.png' })
					break
				case 'js':
					file.push({ key: i, title: 'JavaScript', file: 'assets/images/icons/js2.png' })
					break
				case 'mysql':
					file.push({ key: i, title: 'MySQL', file: 'assets/images/icons/mysql.jpg' })
					break
				case 'mariadb':
					file.push({ key: i, title: 'MariaDB', file: 'assets/images/icons/mariadb.jpg' })
					break
				case 'mssql':
					file.push({ key: i, title: 'MsSQL', file: 'assets/images/icons/sql.jpg' })
					break
				case 'line':
					file.push({ key: i, title: 'LineAPI', file: 'assets/images/icons/line.png' })
					break
				case 'powerbi':
					file.push({ key: i, title: 'Power BI', file: 'assets/images/icons/bi.png' })
					break
				case 'highcharts':
					file.push({ key: i, title: 'HighCharts', file: 'assets/images/icons/highcharts.png' })
					break
				case 'chartsjs':
					file.push({ key: i, title: 'ChartsJs', file: 'assets/images/icons/chartjs.png' })
					break
				case 'draw2d':
					file.push({ key: i, title: 'Draw2D Library', file: 'assets/images/icons/draw2d.png' })
					break
				default :

			}
			i++
		})
	}

	return (
		<>
			<span>เครื่องมือ : </span>
			{file && file.map(({ key, file, title }) => <img key={key} title={title} src={file} alt={title} />)}
		</>
	)
}

export default Tools
