import React from 'react';
import { useState, useEffect } from 'react'
import MainLayout from './components/layouts/MainLayout'
//import DocumentTitle from 'react-document-title'
import MySlide from './MySlide';

function Service() {
	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	useEffect(() => {
		const getData = async () => {
			try {
				const response = await fetch(`data/jobgroup.json`)
				if (!response.ok) {
					throw new Error(`This is an HTTP error: The status is ${response.status}`)
				}
				let actualData = await response.json()
				console.log(actualData)
				setData(actualData)
				setError(null)
			} catch (err) {
				setError(err.message)
				setData(null)
			} finally {
				setLoading(false)
			}
		}
		getData()



		document.title = "Home";  



	}, [])

	return (
		
		<MainLayout>			

			<div className="container mb-5">
			<h1 className="my-4">บริการ</h1>
				<p className="h3">พัฒนาซอฟท์แวร์</p>
				<p class="mt-4">
					- พัฒนา Application เฉพาะทางสำหรับหน่วยงาน องค์กร บริษัทฯ ห้างร้าน
					ด้วยประสบการณ์และความรู้ความเข้าใจในงานด้านต่างๆ เช่น Maintenance, Engineering, Construction, QA/QC,
					Production สามารถพัฒนาในรูปแบบ Web application, Desktop application หรือ Mobile appliction
				</p>
				<p class="mb-4" >
					- เป็นที่ปรึกษาด้าน IT สำหรับงานจัดซื้อจัดจ้างเกี่ยวกับ Hardware, Software และงานจัดทำ Software
					Specification เพื่อการจัดจ้างพัฒนาซอฟท์แวร์
					รวมถึงการเป็นที่ปรึกษาในการควบคุมตรวจสอบงานพัฒนาซอฟท์แวร์
				</p>
				{loading && <div>Loading slides...</div>}
				{error && <div>{`There is a problem fetching slides data - ${error}`}</div>}

        {data && 
          <MySlide data={data.filter(el=>el.projectid===1)}></MySlide>
        }


			</div>

			<hr />

			<div className="container mb-5">
				<p className="h3">Piping design</p>
				<p class="mt-4">- ออกแบบระบบท่อ ถัง และอุปกรณ์ในระบบโพรเซส โรงงานปิโตรเคมี โรงกลั่นน้ำมัน โรงไฟฟ้า</p>
				<p>- จัดทำแบบในระบบ 3D และ Engineering drawing เช่น GA drawing, Isometric drawing เป็นต้น</p>

        {data && 
          <MySlide data={data.filter(el=>el.projectid===2)}></MySlide>
        }

        
			</div>

		

		</MainLayout>

	)
}

export default Service
