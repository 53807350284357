import React, { forwardRef } from "react"
import { NavLink } from "react-router-dom"


const Navbar = (props, ref)=>(

    <>
      <nav
        ref={ref}
        className="navbar fixed-navbar navbar-expand-lg navbar-light bg-bpi"
      >
        <div className="container">
          <NavLink to="/" className="navbar-brand">
            <div>
              <img src="bpi.png" className="App-logo" alt="BPI Logo" />
              <img src="person.png" className="App-logo" alt="Siwach" />
            </div>
            <div className="companyName">BPI Engineering and Service Co., Ltd.</div>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link">
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/software" className="nav-link">
                  Software
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/mechanical" className="nav-link">
                  Mechanical
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/freemium" className="nav-link">
                  Free and Play
                </NavLink>
              </li>
              {/* <li className="nav-item dropdown">
                    <NavLink className="nav-link dropdown-toggle" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        ผลงาน
                    </NavLink>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><NavLink className="dropdown-item" to="/">พัฒนาเว็บไซต์</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/">กราฟฟิก</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/">โปรแกรมมิ่ง</NavLink></li>
                    </ul>
                </li> */}
              <li className="nav-item">
                <NavLink to="/contact" className="nav-link">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="place-holder" style={{ height: props.height + "px" }} />   
      </> 
  
)

export default forwardRef( Navbar)
