import React from 'react'
import {useEffect } from 'react'
import MainLayout from './components/layouts/MainLayout'


function Mechanical() {
	useEffect(() => {
		document.title = "Mechanical";  
	}, []);
	return (
		<MainLayout>
     
			<div className="container mb-5">
       <h1 className="my-4">ตัวอย่างผลงานด้าน Piping design</h1>
				<article>
					<p className="h3 my-4 mb-0">Project RGC Sales Gas Modification</p>
					<p className="h5">Owner : บริษัท ปตท จำกัด(มหาชน)</p>
					<p className="pt-2">ออกแบบ, จัดทำ 3D Modeling, Engineering drawing และ Stress analysis</p>
				</article>

				<article>
					<p className="h3 my-4 mb-0">Revamp LPG project</p>
					<p className="h5">Owner : บริษัท ปตท จำกัด(มหาชน)</p>
					<p className="pt-2">ออกแบบ, จัดทำ 3D Modeling, Engineering drawing และ Stress analysis</p>
				</article>

				<article>
					<p className="h3 my-4 mb-0">Bangchak Biodiesel Project</p>
					<p className="h5">Owner : บริษัท บางจากไบโอฟูเอล จำกัด</p>
					<p className="pt-2">ออกแบบ, จัดทำ 3D Modeling และ Engineering drawing</p>
				</article>

				<article>
					<p className="h3 my-4 mb-0">LP Flare Project</p>
					<p className="h5">Owner : บริษัท พีทีที โกลบอล เคมิคอล จำกัด (มหาชน)</p>
					<p className="pt-2">ออกแบบ, จัดทำ 3D Modeling และ Engineering drawing</p>
				</article>
			</div>
		</MainLayout>
	)
}

export default Mechanical
