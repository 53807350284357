import React  from 'react';
import {Route ,Routes} from 'react-router-dom'
import Service from './Service';
import Software from './Software.jsx';
import Mechanical from './Mechanical.jsx';
import Freemium from './Freemium';
import Contact from './Contact';
import {} from './App.css';


function App() {
  return (
    <Routes>
      <Route path="/" exact={true} element={<Service />} />
      <Route path="/software" element={<Software />} />
      <Route path="/mechanical" element={<Mechanical />} />
      <Route path="/freemium" element={<Freemium />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;
