import React  from 'react';
import { useState, useEffect } from 'react'
import MainLayout from './components/layouts/MainLayout'
import MySlide from './MySlide'
import Tools from './components/shared/Tools';

function Software() {
	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	useEffect(() => {
		const getData = async () => {
			try {
				const response = await fetch(`data/item.json`)
				if (!response.ok) {
					throw new Error(`This is an HTTP error: The status is ${response.status}`)
				}
				let actualData = await response.json()
				//console.log(actualData)
				setData(actualData)
				setError(null)
			} catch (err) {
				setError(err.message)
				setData(null)
			} finally {
				setLoading(false)
			}
		}
		getData()

		document.title = "ผลงานด้านซอฟท์แวร์";  

	}, [])

	return (
		<MainLayout>


		
			<div className="container mb-5">
      	<h1 className="my-4">ตัวอย่างผลงานด้านซอฟท์แวร์</h1>

				{loading && <div>Loading slides...</div>}
					{error && <div>{`There is a problem fetching slides data - ${error}`}</div>}
				<p>
					เป็นตัวอย่างผลงานด้านซอฟท์แวร์บางส่วน และเนื่องจากส่วนใหญ่เป็นงานที่พัฒนาให้กับองค์กร
					ข้อมูลบางส่วนจึงไม่สามารถเปิดเผยโดยพละการได้จึงมีความเป็นจะต้องเบลอในบางจุด
				</p>
				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">Production Monitoring System</p>
					<p className="h5">ลูกค้า : บริษัท จี-เทคคุโตะ (ประเทศไทย) จำกัด</p>
					<p className="small">
						<Tools data={["php", "ci", "js", "bootstrap","jquery", "mysql","line"]} />
					</p>
					<p className="pt-2">
						ระบบที่เชื่อมโยงข้อมูลจากระบบ ERP และข้อมูลจากเครื่องจักรในสายการผลิตเข้าด้วยกัน
						เพื่อแสดงและติดตามผลการผลิตตามเวลาจริง ปัญหาที่เกิดในระบบการผลิต คำนวณประสิทธิภาพด้านต่างๆ
						แสดงเปรียบเทียบแผนกับงานที่ทำได้จริง แสดงสถานะเครื่องจักร รวมถึงจัดทำรายงานต่างๆ
					</p>



					{data && <MySlide data={data.filter((el) => el.projectid === 1)}></MySlide>}
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">QA Project Management</p>
					<p className="h5">ลูกค้า : บริษัท จีซี เมนเทนแนนซ์ แอนด์ เอนจิเนียริง จำกัด</p>
					<p className="small">
						<Tools data={["php", "ci", "js", "bootstrap","jquery", "mysql", "line"]} />
					</p>
					<p className="pt-2">
						เป็นระบบ “Self-assessment and Auditing for Project Management”
						เพื่อให้ผู้ปฎิบัติหน้าที่ในตำแหน่งต่างๆ ภายในโครงการ สามารถทำการประเมิน ตนเองในด้านต่างๆ
						ตามข้อกำหนดของตำแหน่ง
					</p>

					{data && <MySlide data={data.filter((el) => el.projectid === 2)}></MySlide>}

					<p>
						ผู้ควบคุมดูแลในระดับต่างๆ สามารถตรวจสอบและติดตามผลการทำการประเมินตนเอง
						ของผู้ปฏิบัติงานได้อย่างถูกต้องครบถ้วนและครอบคลุมประเด็น สามารถ วิเคราะห์ QMS performance
						ในภาพรวมและ รายบุคคล รวมถึงการรายงานได้อัตโนมัติ
					</p>
					<p>
						สามารถจัด priority ตามความสำคัญของ VOC, Lesson Learnt, Gap Improvement by Audit และ Regular
						Audit ที่เกิดขึ้นต่างๆ
					</p>
				</article>

				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">
						Bad Actors Maintainability Performance Application
					</p>
					<p className="h5">ลูกค้า : บริษัท ไออาร์พีซี จำกัด (มหาชน)</p>
					<p className="small">
						<Tools data={["php", "ci", "js", "bootstrap","jquery", "mssql", "powerbi", "chartsjs"]} />
					</p>
					<p className="pt-2">
						คำนวณ MTBF, MTRR, HighCost และ Re-work โดยอาศัยข้อมูล Workorder จากระบบ SAP
						โดยแสดงผลในรูปแบบตารางและกราฟ รวมทั้ง Export ข้อมูลในรูปแบบ excel
					</p>
					{data && <MySlide data={data.filter((el) => el.projectid === 3)}></MySlide>}
					<p>
						สามารถกำหนดเงื่อนไขและการแสดงผลในรูปแบบต่างๆ แสดงผลรายตัวและรายกลุ่ม
						และจัดเตรียมข้อมูลสำหรับจัดทำ dashboard ใน Power BI
					</p>
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">
						Bow-tie js chart plugin for YellowFin
					</p>
					<p className="h5">ลูกค้า : Company from Australia</p>
					<Tools data={["js", "draw2d"]} />
					<p className="pt-2">สร้าง Custom Chart ในรูปแบบ Bow-tie ที่สามารถปรับเปลี่ยนไปตามฐานข้อมูล</p>
					{data && <MySlide data={data.filter((el) => el.projectid === 4)}></MySlide>}
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">SIL Bow-tie Assessment</p>
					<p className="h5">ลูกค้า : บริษัท ไออาร์พีซี จำกัด (มหาชน)</p>
						<Tools data={["c#", "dotnet", "js", "jquery", "draw2d"]} />
					<p className="pt-2">
						ประเมิน SIL ในรูปแบบของการสร้าง diagram แบบ bow-tie ที่สามารถปรับแต่งได้แบบ interactive
					</p>
					{/* {data && <MySlide data={data.filter((el) => el.projectid === 5)}></MySlide>} */}
					<div className="youtube-container">
						<iframe className="responsive-iframe" src="https://www.youtube.com/embed/p2FptJTH59c" title="SIL Bow-tie Assessment" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">QC RFI and Punch Tracking</p>
					<p className="h5">ลูกค้า : TPSC (Thailand) Co., Ltd.</p>
					
          <Tools data={["php", "ci", "js", "jquery", "bootstrap", "mysql"]} />
					<p className="pt-2">
						ระบบบันทึกและติดตามสถานะเอกสาร RFI และ Punch list พร้อมด้วยระบบรายงานในรูปแบบต่างๆ
					</p>
					{data && <MySlide data={data.filter((el) => el.projectid === 6)}></MySlide>}
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">Reliability data management</p>
					<p className="h5">ลูกค้า : บริษัท ไออาร์พีซี จำกัด (มหาชน)</p>
          <Tools data={["php", "ci", "js", "jquery", "bootstrap", "mssql"]} />
					<p className="pt-2">
						ช่วยในการคำนวณค่า และจัดทำรายงาน ที่เกี่ยวข้องกับ plant reliability โดยอาศัยข้อมูลจาก plant
						turnaround
					</p>
					{/* {data && <MySlide data={data.filter((el) => el.projectid === 7)}></MySlide>} */}
					<div className="youtube-container">
						<iframe className="responsive-iframe" src="https://www.youtube.com/embed/hkX4IGt_LPA" title="Reliability management" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">Incident Tracking</p>
					<p className="h5">ลูกค้า : บริษัท จีซี เมนเทนแนนซ์ แอนด์ เอนจิเนียริง จำกัด</p>					
          <Tools data={["php",  "js", "jquerym", "mysql"]} />
					<p className="pt-2">ใช้ประเมินและติดตามสถานะ incident พร้อมระบบรายงาน</p>
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">Transport service tracking</p>
					<p className="h5">ลูกค้า : บริษัท เดอะ ริช ทรัค แมนเนจเมนจ์ จำกัด</p>
          <Tools data={["php", "ci", "js", "jquery", "bootstrap", "mysql", "line"]} />
					<p className="pt-2">
						ระบบบันทึกรายรับ รายจ่าย ของระบบขนส่ง มีระบบการบันทึกเครดิตการเติมน้ำมันเชื้อเพลิง เพื่อ
					</p>
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">Safety Critical Elements</p>
					<p className="h5">ลูกค้า : บริษัท ไออาร์พีซี จำกัด (มหาชน)</p>
          <Tools data={["c#", "dotnet",  "js", "jquery", "bootstrap", "mssql"]} />
					<p className="pt-2">
						ระบบช่วยในการประเมินความเสี่ยง ก่อนการเลื่อนการซ่อมบำรุง
						พร้อมด้วยระบบการส่งอนุมัติตามลำดับขั้นแบบออนไลน์
					</p>
					{data && <MySlide data={data.filter((el) => el.projectid === 8)}></MySlide>}
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">
						Metallurgical Lab Report Creator
					</p>
					<p className="h5">ลูกค้า : บริษัท ไออาร์พีซี จำกัด (มหาชน)</p>
          <Tools data={["c#", "dotnet",  "js", "jquery", "bootstrap", "mssql"]} />
					<p className="pt-2">
						ระบบช่วยในการบันทึกผลการตรวจสอบ การทดสอบ ในทุกรูปแบบ และช่วยในการจัดทำรายงานโดยอัตโนมัติ
						ในรูปแบบที่ยืดหยุ่น
					</p>
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">
						MA Customer satisfaction Survey
					</p>
					<p className="h5">ลูกค้า : บริษัท ไออาร์พีซี จำกัด (มหาชน)</p>
           <Tools data={["php", "ci",  "js", "jquery", "bootstrap", "mssql", "chartsjs"]} />
					<p className="pt-2">
						ส่งแบบประเมินผลการซ่อมบำรุงตาม workorder โดยอัตโนมัติเมื่อจบงาน รวบรวมและคำนวณผลการประเมิน
						และแสดงในรูปแบบต่างๆ ทั้งแบบตารางและกราฟ พร้อมด้วยส่วนจัดการและตั้งค่าในรูปแบบต่างๆ
					</p>
          {data && <MySlide data={data.filter((el) => el.projectid === 10)}></MySlide>}
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">Failure Recording</p>
					<p className="h5">ลูกค้า : บริษัท ไออาร์พีซี จำกัด (มหาชน)</p>
           <Tools data={["c#", "dotnet",  "js", "jquery", "bootstrap", "mssql", "chartsjs"]} />
					<p className="pt-2">
						บันทึกผล วิเคราะห์ คำนวน และแสดงผลในรูปแบบตาราง กราฟ สำหรับความบกพร่องของอุปกรณ์ intrument
					</p>
					{data && <MySlide data={data.filter((el) => el.projectid === 9)}></MySlide>}
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">RRM to SAP</p>
					<p className="h5">ลูกค้า : บริษัท ไออาร์พีซี จำกัด (มหาชน)</p>
          <Tools data={["php",  "js", "jquery",  "mssql"]} />
					<p className="pt-2">
						นำเข้าข้อมูล ทำให้สมบูรณ์ transform และตรวจสอบความถูกต้อง ของข้อมูลระบบงานซ่อมบำรุง เช่น Task
						list, Plan ก่อนส่งเข้าระบบ SAP
					</p>
				</article>
				<hr />

				<article>
					<p className="h3 p-2 my-4 mb-0 bg-primary text-white rounded">SAP Master Data requisition</p>
					<p className="h5">ลูกค้า : บริษัท ไออาร์พีซี จำกัด (มหาชน)</p>
          <Tools data={["c#", "dotnet",  "js", "jquery", "bootstrap", "mssql"]} />
					<p className="pt-2">
						จัดส่งเอกสารการขอเพิ่ม แก้ไข master data ตาม flow ที่กำหนด และติดตามสถานะ
						พร้อมระบบแจ้งเตือนผ่านระบบเมล์
					</p>
				</article>



			</div>
		</MainLayout>
	)
}

export default Software
