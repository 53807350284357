
import React  from 'react';
import Carousel from 'react-bootstrap/Carousel'

function MySlide(props) {


	return (<>
				<Carousel>
					{props.data &&
            props.data
						.map(({ key, title, file, desc }) => (
							<Carousel.Item key={key}>
								
								<img className="d-block w-100" src={file} alt={title} />
								<Carousel.Caption>
									<h3>{title}</h3>
									<p>{desc}</p>
								</Carousel.Caption>
							</Carousel.Item>
						))}
				</Carousel>		
				</>
	)
}

export default MySlide
