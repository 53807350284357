import React, { useState, useEffect, useRef } from 'react';
import Navbar from "../shared/Navbar";
import Footer from "../shared/Footer";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function MainLayout({children}) {
  const [headerH, setHeaderH] = useState(0)
  const [footerH, setFooterH] = useState(0)
  const [bodyH, setBodyH] = useState(0)
  const [fixedClass, setFixedClass] = useState("");
  
  const bodyContainer = useRef(null)
  const headerContainer = useRef(null)
  const footerContainer = useRef(null)
  const defaultFixedClass = "fixed-bottom"

  useEffect(() => {
    window.addEventListener("resize", handleResize2)  

    let time1 = setTimeout(() => {
      handleResize2();
    }, 100);
    
    function handleResize2() {      

      if (headerContainer && footerContainer && bodyContainer){
      
          console.log('resized to 2: ', window.innerWidth, 'x', window.innerHeight)
          
  
          let wH = getWindowDimensions().height;
          let headerH = headerContainer.current.clientHeight;
          let footerH = footerContainer.current.clientHeight;
          let bodyH = bodyContainer.current.clientHeight;
          //console.log('wh=',wh);
          //bodyContainer.current.clientHeight && setHeight(wh);
          if((bodyH + headerH +footerH)>=wH){
            setFixedClass("")
          }else{
            setFixedClass(defaultFixedClass)
          }

          setHeaderH(headerH);
          setFooterH(footerH);
          setBodyH(bodyH);
  
          console.log('wH=', wH, ", headerH=", headerH, ", footerH=", footerH, ", bodyH=", bodyH);
      }
    }

    return () => {
      window.removeEventListener('resize', handleResize2)
      clearTimeout(time1)
    }
    
  },[headerH, footerH, bodyH])







  return (
      <>
          <Navbar ref={headerContainer} height={ headerH } />
          <div ref={ bodyContainer }>{children}</div>
          <Footer ref={footerContainer}  height={ footerH }  cls={ fixedClass } />
      </>
  );
}

export default MainLayout;
