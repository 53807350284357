import React from "react"
import { useEffect } from "react"
import MainLayout from "./components/layouts/MainLayout"
import Card from "react-bootstrap/card"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

function Freemium() {
  useEffect(() => {
    document.title = "ฟรีและเล่น"
  }, [])

  return (
    <MainLayout>
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3880.4645483021413!2d101.03163371534552!3d13.44543670794028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d370465112e59%3A0xaca69425f428e248!2z4Lia4Lij4Li04Lip4Lix4LiXIOC5guC4i-C4meC4teC5iCDguYDguJfguITguYLguJnguYLguKXguKLguLUgKOC4m-C4o-C4sOC5gOC4l-C4qOC5hOC4l-C4oikgKOC4quC4suC4guC4siAxKQ!5e0!3m2!1sth!2sth!4v1642927369044!5m2!1sth!2sth" width={'100%'} height={500} style={{border: 0}} allowFullScreen loading="lazy" /> */}

      <div className="container mb-5">
        <h1 className="my-4">ทำเล่น ทำลอง ทำใช้ ทำแจก</h1>
        <p>
          เป็นโปรแกรมที่เริ่มต้นจากปัญหาที่พบเจอมา ไม่ได้เป็นระบบใหญ่ๆ
          แบบโปรเจ็คอื่นๆ แต่ทำมาเพื่อแก้ปัญหาเฉพาะอย่าง ยังไม่มี Feature ต่างๆ
          ครบครัน แต่แก้ปัญหาที่พบมาได้ครบถ้วน
        </p>
        <p>บางส่วนก็จะเป็น prototype หรือตัวอย่าง ที่นำเสนอลูกค้า ฟังก์ชันการทำงานอาจยังไม่สมบูรณ์ แค่พอให้เห็นภาพ พวกนี้จะใส่ไว้เป็นตัวอย่างเฉยๆ ครับ</p>
        <p>ก็จะระบุไว้ในแต่ละตัวว่าเป็นแบบไหน ลองไปดูกัน....</p>
        <div>
          
          <p>
            1. <a href="#formmini-app">
              form mini</a> โปรแกรมช่วยให้การลงชื่อในกลุ่มไลน์ ง่ายขึ้น ตัวนี้ใช้งานได้จริง ใช้ได้ฟรี            
          </p>
          <p>
            2. PWA Example ตัวนี้เป็นตัวอย่าง PWA หรือ Progressive Web Application ที่ทำให้ web application ธรรมดา กลายเป็น Mobile app อาจไม่สามารถทำฟังก์ชันลึกๆ ได้เท่าพวก Native แต่ข้อดีคือ เขียนทีเดียวใช้ได้หมดทุกที่ <a target="_blank" href="https://app.therichtruck.com">https://app.therichtruck.com</a>
          </p>
          <p style={{display: 'none'}}>
            3. <a href="#qtrack-app">Q-Tracking โปรแกรมช่วยเช็คคิวออนไลน์</a> ตัวนี้ใช้งานได้จริง ใช้ได้ฟรี
          </p>

          <p style={{display: 'none'}}>
            4. <a href="#qtrack-app">Auto Check in Through LINE</a> ตัวนี้เป็นตัวอย่างหรือ prototype ที่นำเสนอลูกค้า ส่วนที่เป็น option เพิ่มเติมให้กับระบบ Auto-checkin ผ่านทาง LINE LIFF โดยเป็นตัวเลือกเพิ่มกรณีพื้นที่ไม่มี LINE DEVIO Beacon
          </p>
        </div>
      </div>

      <div id="formmini-app" />


      <div className="container mb-5">
        <h3>1. fommini</h3>
        <p>...ช่วยให้การลงชื่อในกลุ่มไลน์....ง่ายขึ้น</p>
        <img
            alt="start"
            className="img-fluid rounded mx-auto d-block"
            style={{ minWidth: 50 }}
            src="https://bpi.co.th/assets/images/182632.jpg"
        />
        <div className="h1 text-center mt-3 p-3 text-primary">
          บอกลา...การลงชื่อ แบบ
        </div>
        <div className="h1 text-center mt-3 p-3 ms-3 me-3 bg-danger rounded text-white text-decoration-line-through">
          คัดลอก-&gt;วาง-&gt;พิมพ์ชื่อ-&gt;ส่ง
        </div>
        <div className="text-center">
          <img src="https://bpi.co.th/assets/images/down.png" className="App-logo" alt="down" />
        </div>
        <div className="h1 text-center m-3  p-3 bg-success text-white rounded">
          <span className="fa fa-check" />
          ลงผ่านฟอร์ม ที่สร้างง่าย ลงง่าย
        </div>
        <div className="h1 text-center mt-3 p-3 text-primary rounded">
          ฟอร์แบบง่ายๆ ใช้ง่ายๆ
        </div>
        <div className="h1 text-center mt-3 p-3 text-primary rounded">
          ใช้ฟรี ไม่มีค่าใช้จ่าย
        </div>
        <div className="h1 text-center mt-3 p-3 bg-info rounded ms-4 me-4">
          <span className="text-danger fa fa-arrow-down pe-2" />
          ลองใช้เลย
          <span className="text-danger fa fa-arrow-down ps-2" />
        </div>
        <a href="https://liff.line.me/1656914153-3y8Q7A7G">
          <img
            alt="start"
            className="img-fluid rounded mx-auto d-block"
            style={{ minWidth: 50 }}
            src="https://formmini.com/public/assets/images/page/startImage.png"
          />
        </a>
        <div className="h1 text-center mt-3 p-3 bg-info rounded ms-4 me-4">
          <span className="text-danger fa fa-arrow-down pe-2" />
          หรือ
          <span className="text-danger fa fa-arrow-down ps-2" />
        </div>
        <div className="text-center">
          <img src="https://bpi.co.th/assets/images/down.png" className="App-logo" alt="down" />
        </div>
        <div className="h1 text-center mt-3 p-3">
          <a href="https://lin.ee/252KsLS">
            <img
              src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"
              alt="เพิ่มเพื่อน"
              height={36}
            />
          </a>
        </div>
        <img
          alt="add friend"
          className="mx-auto d-block"
          src="https://qr-official.line.me/sid/M/143jiupw.png?shortenUrl=true"
        />
      </div>


     


      <div className="container mb-5" id="qtrack-app" style={{display: 'none'}}>
        <Card>
          <Card.Body>
            <Card.Title className="bg-primary text-white p-2 rounded">Q-Tracking</Card.Title>
            <Card.Subtitle className="mt-3 mb-2 text-primary">ที่มา :</Card.Subtitle>
            <Card.Text>
              ....ย้อนกลับไปตอนไปรับวัคซีนโควิดครั้งแรก
              เป็นการให้บริการนอกพื้นที่ในห้างสรรพสินค้าของโรงพยาบาล
              ที่มีขั้นตอนทำงานดังนี้
            </Card.Text>
            <Card.Text className="ms-4">
              <li>ผู้รับการฉีดฯ ติดต่อที่โต๊ะ เพื่อตรวจสอบรายละเอียดส่วนบุคคลต่างๆ
              และรับบัตรคิว โดยจะได้รับบัตรเป็นสีตามประเภทที่ รพ แบ่งแยก เช่น
              อายุ เข็มที่ .... ในบัตรจะระบุเป็นลำดับที่ไว้</li>
              <li>ผู้ที่ได้รับบัตรแล้ว รอฟังการเรียกคิว โดยการเรียกจะระบุสีบัตร
              และลำดับที่ โดยจะเรียกครั้งละหลายคน
              เพื่อเข้าไปในขั้นตอนถัดไปของการฉีด ตัวอย่างการเรียกคิว เช่น
              ...บัตรสีแดงหมายเลข 250 ถึง 300 เชิญ....
              </li>
            </Card.Text>
            <Card.Subtitle className="mt-3 mb-2 text-danger">ปัญหา :</Card.Subtitle>
            <Card.Text>
             เนื่องจากมีผู้เข้ารับบริการจำนวนมาก การรอคิวจะนานพอสมควร
            และการรอฟังการเรียกคิว ต้องอยู่ในบริเวณใกล้ๆ ที่จะได้ยินเสียงได้
            ทำให้ไม่สามารถที่จะออกจากบริเวณไปโดยไม่กังวลเรื่องคิวได้
            </Card.Text>
            <Card.Subtitle className="mt-3 mb-2 text-primary">ระบบช่วยอะไร :</Card.Subtitle>

            <Card.Text>
             ระบบจะช่วยแสดงลำดับคิว ณ ปัจจุบันผ่านมือถือ(เว็บ
            browser) ให้ผู้รับการฉีดฯ
            ได้ทราบได้ว่าคิวปัจจุบันอยู่ที่ลำดับเท่าไหร่
            โดยไม่จำเป็นต้องคอยอยู่ในพื้นที่ได้ยินเสียงเท่านั้น
            </Card.Text>
            <Card.Subtitle className="mt-3 mb-2 text-success">ผู้เกี่ยวข้องกับระบบ :</Card.Subtitle>
             <li>ผู้ให้บริการหรือ รพ. สร้างบริการหรือ site
            พร้อมกำหนด ประเภทบัตรที่ต้องการใช้(ทำครั้งเดียว)</li>
            <li>ผู้ให้บริการ
            พิมพ์ QR Code ติดในบริเวณเพื่อให้ผู้รับการฉีดฯ
            สแกนเพื่อเข้าหน้าเช็คคิวได้ หรือจะแชร์ QR Code หรือ Link
            ไปในกลุ่มก็ได้(ทำครั้งเดียว)</li>
            <li>ผู้เรียกคิว กดปรับเลขคิว
            ผ่านมือถือ(หรือคอมฯ) ตรงกับการเรียกคิว ในทุกรอบที่เรียกคิว</li>
            <li>ผู้รับการฉีดฯ สแกน QR Code เพื่อเปิดหน้าเช็คคิวบนมือ หน้าจะ update
            ข้อมูลล่าสุดให้ตลอด (QR Code แชร์ได้)</li>
            
            <Card.Subtitle className="mt-3 mb-2 text-primary">เข้าระบบ</Card.Subtitle>

            <Card.Subtitle className="mt-3 mb-2 text-secondary">หมายเหตุ :</Card.Subtitle>
            <Card.Text className="text-secondary">
              <li>เป็นระบบที่ทำขึ้นภายในเวลาหนึ่งวัน เพื่อทดลองและแก้ปัญหาเฉพาะเรื่อง
            คุณสมบัติบางอย่างอาจยังไม่ครบถ้วน
            หากนำไปใช้กับการบริการที่รูปแบบแตกต่างกัน</li>
            <li>ระบบอยู่บน server
            ของบริษัทที่เปิดมานานแล้ว และมั่นใจว่าใช้ได้ตลอด 24 ชม.</li>
            <li>ยังไม่ได้ใส่ระบบแจ้งเตือนเข้าไป
            การดูข้อมูลจะต้องเปิดหน้าโปรแกรม(web)เพื่อดูเท่านั้น</li>
            <li>เป็นระบบแจ้งสถานะคิวปัจจุบันผ่านเว็บไซต์ - สามารถสร้างจุดบริการ
            และกำหนดจำนวนช่องได้เอง - แชร์จุดบริการผ่าน QR Code และ Link</li>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </MainLayout>
  )
}

export default Freemium
